<!-- Localized -->
<template>
    <modal-content ref="list-modal" height="auto" :showFooter="false" :max-width="480" name="list-modal" :title="modalTitle || 'Header'" @close.stop="$emit('closeModal')">
        <template #content>
            <div class="h-96 flex justify-center items-center" v-if="isLoading">
                <Loader :loading="isLoading" />
            </div>
            <div v-else class="h-96">
                <ListView :listItems.sync="lists" :showSearch="showSearch" class="h-full" @handleFilterResult="$emit('handleFilterResult', $event)">
                    <template #item="{ item }">
                        <slot name="item" :item="item">
                            {{item.name}}
                        </slot>
                    </template>
                </ListView>
            </div>
            <slot name="footer" class="p-2"> </slot>
        </template>
    </modal-content>
</template>
<script>
import Loader from "../../loader";
import modalContent from "@shared/modal-content";
import ListView from "../list-view";

export default {
    name: "list-modal",
    components: {
        Loader,
        modalContent,
        ListView,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        lists: {
            type: Array,
            default: () => [],
        },
        modalTitle: {
            type: String,
            default: () => "Header",
        },
        showSearch:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    mounted() {
    },
    computed: {},
    methods: {
        closeModal() {
            this.$refs["list-modal"].hideModal();
        },
        showListModal() {
            this.searchValue = "";
            this.$refs["list-modal"].showModal();
        },
    },
};
</script>
<style scoped></style>
